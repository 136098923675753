import axios from 'axios'
import baseInterceptor from './baseInterceptor'
import { getEnvVariable } from '@/helpers'

const AxiosNotificator = axios.create({
  baseURL: getEnvVariable('VUE_APP_NOTIFICATOR_API_URL', process.env.VUE_APP_NOTIFICATOR_API_URL),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

baseInterceptor(AxiosNotificator)

export default AxiosNotificator
/* eslint-enable no-param-reassign */
